// Generated by Framer (3ae0a1a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["tBdG99fal"];

const serializationHash = "framer-sZ4Hn"

const variantClassNames = {tBdG99fal: "framer-v-10tenww"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, GpOIbGOaH: link ?? props.GpOIbGOaH, PqTzdHgz3: title ?? props.PqTzdHgz3 ?? "Home"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GpOIbGOaH, PqTzdHgz3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "tBdG99fal", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={GpOIbGOaH} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-10tenww", className, classNames)} framer-1xcrc4e`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tBdG99fal"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLXJlZ3VsYXI=", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255)))"}}>Home</motion.p></React.Fragment>} className={"framer-ln8tfv"} fonts={["GF;Open Sans-regular"]} layoutDependency={layoutDependency} layoutId={"AN8GE3ZI4"} style={{"--extracted-r6o4lv": "var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={PqTzdHgz3} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sZ4Hn.framer-1xcrc4e, .framer-sZ4Hn .framer-1xcrc4e { display: block; }", ".framer-sZ4Hn.framer-10tenww { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 4px; position: relative; text-decoration: none; width: min-content; }", ".framer-sZ4Hn .framer-ln8tfv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sZ4Hn.framer-10tenww { gap: 0px; } .framer-sZ4Hn.framer-10tenww > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-sZ4Hn.framer-10tenww > :first-child { margin-left: 0px; } .framer-sZ4Hn.framer-10tenww > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 53
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"GpOIbGOaH":"link","PqTzdHgz3":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOfO0svx4B: React.ComponentType<Props> = withCSS(Component, css, "framer-sZ4Hn") as typeof Component;
export default FramerOfO0svx4B;

FramerOfO0svx4B.displayName = "nav Link";

FramerOfO0svx4B.defaultProps = {height: 35, width: 53};

addPropertyControls(FramerOfO0svx4B, {GpOIbGOaH: {title: "Link", type: ControlType.Link}, PqTzdHgz3: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerOfO0svx4B, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4iY1M2xLER.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})